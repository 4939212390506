.ticketsLayout {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 20px;
  grid-auto-rows: minmax(200px, auto);
}
.mostSoldContainer{
  /* display: flex; */
}
.departamentProductsContainer{
  display: flex;
  justify-content: center;
}
.productInfo{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #59a3e5;
  border: black 6px double ;
  border-bottom-right-radius: 10px ;
  border-top-left-radius: 10px ;
}
.productInfo:hover{
  cursor: pointer;
  background-color: #1087ff;
}
.toggleAnalyticsButton{
  position: fixed;
  left: -25px;
  top: 30px;
}
.allTicketsContainer{
  grid-column: 2;
  grid-row: 0;
  display: flex;
  /* background-color: red; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.one {
  grid-column: 1 / 3;
  grid-row: 1;
}
.two {

}
.three {

}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {

}
.six {
  grid-column: 3;
  grid-row: 4;
}

.productTicketCard {
  background-color: #E8DCCA;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.mostSoldToday{
position: fixed;
top: 30%;
right: 0;
display: flex;
flex-direction: column;
  border: black solid 3px;
  background-color: #E8DCCA;
  /* grid-column: 1;
  grid-row: -3; */
}
.mostSoldToday:hover{
  cursor: pointer;
  background-color: #E8DCCA;
}
.mostSoldCard{
  width: 100%;
  display: flex;
  flex-direction: column;
  border: blue dotted 1px;
  background-color: #E8DCCA;
}
.mostSoldCard:hover{
  background-color: #76baff;
}
.departmentProductsAnalysisContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border: black double 3px;
  padding: 5px;
}
.productCard{
  padding: 5px;
  color: white;
  font-weight: 200;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  filter: drop-shadow(2px 2px 2px black);
  /* background-color: red; */
}
.productName{
  /* background-color: rebeccapurple; */
  filter: drop-shadow(2px 2px 1px rgb(0, 0, 0));
  /* border: 1px black solid; */
  /* font-weight: 20pxs; */
}
.productPrice{
  /* background-color: rebeccapurple; */
  filter: drop-shadow(2px 2px 1px rgb(0, 0, 0));
  /* font-weight: 20pxs; */
}
.departamentAnalysisContainer{
  padding: 10px;
}
.DayAnalysisContainerActive{
  display: none;
}
.ticketProducts {
  width: 200px;
  
  border: solid black 3px;
  display: flex;
  flex-direction: column;
}
.cardMarker{
  background-color: rgb(143, 143, 209);
  width: 100%;
  height: 100%;
  color: white;
  filter: drop-shadow(2px 2px 1px black);
}
.ticketTotalOut {
  background-color: rgb(116, 221, 70);
  padding: 5px;
}

.ticketTotalEntry {
  background-color: rgb(252, 51, 51);
  padding: 5px;
}

.ticketTotalPending {
  background-color: rgb(255, 255, 0);
  padding: 5px;
}

.ticketsContainer {
  border: dotted black 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productTicketsPrice {
  background-color: greenyellow;
  border: 2px dotted black;
  padding: 3px;
  color: black;
}

.productTicketsName {
  background-color: transparent;
  padding: 3px;
  color: black;
}

.productTicketsQuantity {
  background-color: transparent;
  padding: 5px;
  color: red;
  filter: drop-shadow(1px 1px 2px white);
  /* color: black; */
}

.calendarTicket {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.favGroup {
  display: flex;
  flex-direction: row;
  grid-column: 2 ;
  grid-row: 1 ;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
  /* background-color: red; */
}

.calendarMiniApp {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  /* z-index: 3; */

}

.ticketMiniApp {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  grid-column: 1;
  grid-row: 1 / 3;

}

.entryTotal {
  background-color: rgb(252, 51, 51);
  padding: 5px;
}

.outTotal {
  background-color: rgb(116, 221, 70);
  padding: 5px;
}

.meanEntry {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: rgb(214, 99, 99);
}

.meanOut {
  display: flex;
  flex-direction: column;
  background-color: rgb(178, 241, 150);
  padding: 5px;
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.deleteTicketButton {
  flex-direction: row-reverse;
  justify-content: center;
  width: 10%;
  align-items: center;
  cursor: pointer;
  position: relative;
  right: 40%;
  padding: 5px;
  color: white;
}

.ticketTotal {
  padding: 5px;
}

.ticketCreatedAt {
  font-weight: bold;
}
.departmentAnalysisProductContainer{
  border: black solid 1px;
}
@media (max-width: 800px) {
  .ticketsContainer {
    width: 60%;
  }
  .ticketMiniApp {
    display: flex;

    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap;
    grid-column: 1;
    grid-row: 1 / 5; */
  }
  .ticketsLayout{
    display: flex;
    flex-direction: column;
  }
  .favGroup{
    flex-direction: column;
  }

}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: rgb(182, 182, 182);
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #9a9a9a;
}

.react-calendar__navigation button[disabled] {
  background-color: #6d6d6d;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  color: black; 
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}